import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const DiscografiaEsPrimavera = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Frutos de la Memoria – 2008"
        titleTemplate="%s | Eduardo Ceballos"
        description="Frutos de la Memoria – 2008"
        canonical="https://eduardoceballos.com/discografia/frutos-de-la-memoria"
        openGraph={{
          url: `https://eduardoceballos.com/discografia/frutos-de-la-memoria`,
          title: `Frutos de la Memoria – 2008`,
          description: `Frutos de la Memoria – 2008`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/discografia.jpg`,
              alt: `Frutos de la Memoria – 2008`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Frutos de la Memoria – 2008</h1>
      <center>
        <img
          className="discos-img"
          style={{ float: "Right", marginLeft: "10px" }}
          src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdfrutosdelamemoria_9f2deafd02.jpg"
          alt=""
        />
      </center>
      <div>
        <p>
          Los caminos de la vida juntan a los hombres en distintas
          circunstancias. En una ocasión, Rubén Pérez, el taleño, sirvió de nexo
          para que se conocieran Jorge “Cholonga” Navarro y Eduardo Ceballos.
          Ese encuentro fue como un viento emocionado que juntó las semillas y
          el canto, provocó un árbol donde florecen los sueños con guitarra,
          donde se incluyen soles y pájaros que salen a desandar por los caminos
          con historia en un horizonte de voces que traen sentires y canciones.
        </p>
        <p>
          Con el pueblo en la voz surge claro testimonio, que suelta trinos del
          alma para decir estos Frutos de la Memoria.
        </p>
        <p>
          Desde el cauce de la sangre donde vive el silencio, se produce la
          alquimia milagrosa del mensaje con asombro.
        </p>
        <p>
          Desde allá viene subiendo la música y la palabra para contar de los
          paisajes y de los hombres, como un abrazo frutal que irradia una
          auténtica alegría. Un racimo de vibraciones que el artista muestra con
          gesto creativo para explicar su mundo poblado de vacas, lluvias y
          trigo.
        </p>
        <p>
          Desde esa interioridad humana se ofrece como sabroso alimento Frutos
          de la Memoria, pura música, puro gesto, flores nuevas, para ponerlo en
          la mesa existencial y recuperar el niño que vibra dentro de cada uno.
          Que lo disfruten…
        </p>
        <p style={{ textAlign: "right" }}>
          <strong> Eduardo Ceballos.</strong>
        </p>
        <p>
          <strong>Jorge Cholonga Navarro</strong>
        </p>
        <p>
          Nacido en El Tala, ha fijado su residencia definitiva en la Ciudad de
          Rosario de la Frontera, desde donde trabaja para aportar al cancionero
          popular argentino. Cholonga viene de grupos folclóricos por los que ha
          pasado y este es su segundo material discográfico como solista.
        </p>
        <p>
          Permanente en su vocación para reconocer a los hombres y mujeres que
          dejan un fuerte testimonio de su paso por la vida. Su primer cd “Solo
          sueño”, soy de Salta, suma a su historia este nuevo trabajo que ofrece
          Frutos de la Memoria.
        </p>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default DiscografiaEsPrimavera
